import { useState, useEffect } from "react";
import { useMaterialUIController, setLayout } from "context";
import { useLocation } from "react-router-dom";

function Header({ image, children }) {
  const [, dispatch] = useMaterialUIController();
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "page");
  }, [pathname]);
  return (
    <>
      <nav
        id="navbar-main"
        className="navbar navbar-main navbar-expand-lg bg-primary navbar-dark position-sticky top-0  py-2"
      >
        <div className="container">
          <a className="navbar-brand mr-lg-5" href="/">
            <img src="./assets/img/brand/white.png" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar_global"
            aria-controls="navbar_global"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse" id="navbar_global">
            <div className="navbar-collapse-header">
              <div className="row">
                <div className="col-6 collapse-brand">
                  <a href="/">
                    <img src="assets/img/brand/blue.png" />
                  </a>
                </div>
                <div className="col-6 collapse-close">
                  <button
                    type="button"
                    className="navbar-toggler"
                    data-toggle="collapse"
                    data-target="#navbar_global"
                    aria-controls="navbar_global"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
            <ul className="navbar-nav navbar-nav-hover align-items-lg-center ml-lg-auto">
              <li className="nav-item dropdown">
                <a
                  href="javascript:;"
                  className="nav-link"
                  data-toggle="dropdown"
                  role="button"
                >
                  <i className="ni ni-ui-04 d-lg-none"></i>
                  <span className="nav-link-inner--text">SmartVisit</span>
                </a>
                <div className="dropdown-menu dropdown-menu-xl">
                  <div className="dropdown-menu-inner">
                    <a
                      href="/about"
                      className="media d-flex align-items-center"
                    >
                      <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                        <i className="ni ni-spaceship"></i>
                      </div>
                      <div className="media-body ml-3">
                        <h6 className="heading text-primary mb-md-1">About</h6>
                        <p className="description d-none d-md-inline-block mb-0">
                          Learn what SmartVisit does, get access to firsthand
                          information about new features and upcoming releases
                          or join our BETA program and more.
                        </p>
                      </div>
                    </a>
                    <a href="/faq" className="media d-flex align-items-center">
                      <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                        <i className="ni ni-palette"></i>
                      </div>
                      <div className="media-body ml-3">
                        <h6 className="heading text-primary mb-md-1">FAQ</h6>
                        <p className="description d-none d-md-inline-block mb-0">
                          Got questions? explore our FAQ page to get answers to
                          many popular questions, enquiries, bug resolutions on
                          SmartVisit
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="dropdown-menu-footer">
                    <a className="dropdown-item" href="/contact">
                      <i className="ni ni-atom"></i>
                      Be a Reseller
                    </a>
                  </div>
                </div>
              </li>

              <li className="nav-item">
                <a href="/data-protection" className="nav-link" role="button">
                  <i className="ni ni-single-copy-04 d-lg-none"></i>
                  <span className="nav-link-inner--text">Data Protection</span>
                </a>
              </li>
              <li className="nav-item">
                <a href="/contact" className="nav-link" role="button">
                  <i className="ni ni-tablet-button d-lg-none"></i>
                  <span className="nav-link-inner--text">Contact</span>
                </a>
              </li>

              <li className="nav-item dropdown">
                <a
                  href="javascript:;"
                  className="btn btn-white"
                  data-toggle="dropdown"
                  role="button"
                >
                  <i className="ni ni-basket d-lg-none"></i>
                  <span className="nav-link-inner--text">Get Started</span>
                </a>
                <div
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <a className="dropdown-item" href="/login">
                    <i className="ni ni-tv-2 text-danger"></i>
                    Login
                  </a>
                  <a className="dropdown-item" href="/signup">
                    <i className="ni ni-air-baloon text-pink"></i>
                    Register
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
